<template>
  <section class="ls-container">
    <div class="main-section">
      <div class="account-info" v-loading="loading || isUpdate">
        <div class="ls-title">账号信息</div>
        <div class="info-wrap">
          <div class="upload-header">
            <div class="avatar">
              <img :src="userInfo.headImageUrl || defalutAvatar" alt="" />
            </div>

            <el-upload
              class="logo-uploader"
              action="#"
              accept="image/jpeg,image/png"
              :disabled="isUpdate"
              :show-file-list="false"
              :before-upload="beforeLogoUpload"
            >
              <div class="btn">上传头像</div>
            </el-upload>
          </div>
          <div class="account-wrap">
            <div v-for="(item, i) in accountList" :key="i" class="name-wrap">
              <div class="ls-label">{{ item.name }}</div>
              <div class="name-panel">
                <span class="ls-text">{{ item.value }}</span>
                <span v-if="item.iconCss" class="ls-icons icon-edit" @click="openEdit(item.type)"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="identify-info" v-loading="authLoading">
        <div class="ls-title">认证信息</div>
        <div class="identify-wrap">
          <!--- 实名认证 --->
          <div class="identify-item">
            <div class="ls-label">实名认证</div>
            <div class="info-wrap">
              <div v-for="(item, i) in realnameList" :key="i" class="info-item">
                <div class="name">{{ item.name }}</div>
                <div class="value">
                  <span v-if="item.content">{{ item.content }}</span>
                  <span v-if="item.iconCss" class="ls-icons icon-dot" :class="[item.iconCss]"></span>
                  <span class="ls-text" :class="[item.iconCss]">{{ item.value }}</span>
                </div>
              </div>
            </div>
            <div v-if="realNameInfoData && realNameInfoData.auditStatus !== 2" class="ls-btns" @click="toRealnameAuth">去认证</div>
          </div>

          <!--- 企业/机构认证 --->
          <div class="identify-item enterprise">
            <div class="ls-label">
              <span>企业/机构认证</span>
              <div class="hint-wrap">
                <span class="ls-icons icon-hint-warning"></span>
                <span class="ls-hint warning">温馨提示：进行认证时，同一企业或机构信息无法被重复使用</span>
              </div>
            </div>
            <div class="info-wrap">
              <div v-for="(item, i) in enterpriseList" :key="i" class="info-item">
                <div class="name">{{ item.name }}</div>
                <div class="value">
                  <span v-if="item.iconCss" class="ls-icons icon-dot" :class="[item.iconCss]"></span>
                  <span v-if="item.value" class="ls-text" :class="[item.iconCss]">{{ item.value }}</span>
                  <span v-if="item.html" class="ls-text" :class="[item.iconCss]" v-html="item.html"></span>
                </div>
              </div>
            </div>
            <template v-if="enterpriseInfoData">
              <div v-if="enterpriseInfoData.auditStatus === 1 || enterpriseInfoData.auditStatus === 4" class="ls-btns disabled">去认证</div>
              <div v-else-if="enterpriseInfoData.auditStatus !== 2" class="ls-btns" @click="toEnterpriseAuth">去认证</div>
              <div v-if="enterpriseInfoData.auditStatus === 2" class="ls-btns" @click="openEdit('connectInfo')">变更联系人信息</div>
              <div v-if="enterpriseInfoData.auditStatus === 2" class="ls-btns disabled" @click="onHintMessage">企业信息变更</div>
              <div v-if="realNameInfoData && realNameInfoData.auditStatus !== 2" class="ls-hint">
                如需进行企业认证，请先完成账号的实名认证。
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <PromptDialog :item="promptItem" @updateInfo="updateAccountInfo" @updateContactsInfo="updateContactsInfo" />
  </section>
</template>

<script>
/**
 * 账号信息
 */
import dayjs from 'dayjs'
import PromptDialog from '@/components/common/PromptDialog.vue'
import defalutAvatar from '@/assets/images/account/default_h.png'
import { editAccountInfo, realNameInfo, enterpriseInfo, uploadAvatarFile } from '@/api/interface/account'
import { mapGetters, mapMutations, mapActions } from 'vuex'

const IDTYPE = {
  0: '法人',
  1: '代理人'
}

const AUDITSTATUS_TYPE = {
  0: '未认证',
  1: '认证中',
  2: '已认证',
  3: '未通过',
  4: '认证中',
  5: '未通过'
}

export default {
  components: {
    PromptDialog
  },
  data() {
    return {
      defalutAvatar,
      isUpdate: false,
      accountList: [
        { name: '用户名称', value: '-', iconCss: 'icon-edit', type: 'userName' },
        { name: '账号类型', value: '企业/机构账号' },
        { name: '注册时间', value: '-' },
        { name: '注册手机号', value: '-' },
        { name: '联系邮箱', value: '-', iconCss: 'icon-edit', type: 'userEmail' }
      ],
      realnameList: [
        { name: '认证状态', value: '-', iconCss: '' },
        { name: '真实姓名', value: '-' },
        { name: '身份证号', value: '-', iconCss: '' },
        { name: '认证时间', value: '-' }
      ],
      enterpriseList: [
        { name: '身份类别', value: '-' },
        { name: '认证状态', value: '-', iconCss: '' },
        { name: '企业名称', value: '-' },
        { name: '社会信用代码', value: '-' },
        { name: '联系人信息', value: '-' }
      ],
      promptItem: {
        isShow: false
      },
      loading: false,
      authLoading: false,
      realNameInfoData: null, //实名认真信息
      enterpriseInfoData: null // 企业机构信息
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    userInfo: {
      handler(newVal) {
        if (newVal) {
          this.setAccountInfo(newVal)
        } else {
          this.setUserInfo()
        }
      }
    }
  },
  methods: {
    ...mapMutations(['updateUserInfo']),
    ...mapActions(['setUserInfo']),
    onHintMessage() {
      this.$message.info('暂未开放')
    },
    // file转base64
    blobToDataURL(blob, cb) {
      let reader = new FileReader()
      reader.onload = function (evt) {
        let base64 = evt.target.result
        cb(base64)
      }
      reader.readAsDataURL(blob)
    },
    // 获取上传图片文件
    beforeLogoUpload(file) {
      if (!file) return false
      const { size, type } = file || {}
      if (!type.includes('image/')) {
        this.$message.info('请上传图片格式的文件！')
        return false
      }
      if (!size) {
        return false
      }
      if (size > 5242880) {
        this.$message.info('最大支持5M的图片格式文件！')
        return false
      }
      this.uploadAvatar(file)
      // this.blobToDataURL(file, base64Url => {
      //   this.onEditAccountInfo(base64Url)
      // })
      return false
    },
    // 上传头像图片
    uploadAvatar(file) {
      if (this.isUpdate) return
      this.isUpdate = true
      const formData = new FormData()
      formData.append('file', file)
      uploadAvatarFile(formData)
        .then(res => {
          if (res) {
            this.onEditAccountInfo(res)
          } else {
            this.isUpdate = false
          }
        })
        .catch(error => {
          this.isUpdate = false
        })
    },
    // 修改头像
    onEditAccountInfo(fileUrl) {
      editAccountInfo({
        headImageUrl: fileUrl
      })
        .then(res => {
          this.$message.success('修改头像成功')
          this.updateUserInfo({ headImageUrl: fileUrl })
        })
        .finally(() => {
          this.isUpdate = false
        })
    },
    // 去编辑
    openEdit(type) {
      if (type === 'connectInfo') {
        const { contact, phone, email } = this.enterpriseInfoData || {}
        this.promptItem = {
          isShow: true,
          type,
          userInfo: {
            contact,
            phone,
            email
          }
        }
      } else {
        this.promptItem = Object.assign({
          isShow: true,
          type,
          userInfo: null
        })
      }
    },
    // 去实名认证
    toRealnameAuth() {
      this.$router.push('/realname-auth')
    },
    // 企业机构认证
    toEnterpriseAuth() {
      // 必须先实名认证才能企业机构认证
      if (!this.realNameInfoData || this.realNameInfoData.auditStatus !== 2) {
        this.$message.info('如需进行企业认证，请先完成账号的实名认证')
        return
      }
      this.$router.push('/enterprise-auth')
    },
    // 更新信息
    updateAccountInfo(data) {
      const { nickName, email } = data || {}
      this.updateUserInfo({ nickName, email })
      this.accountList[0]['value'] = nickName
      this.accountList[4]['value'] = email
    },
    // 修改企业联系人信息
    updateContactsInfo(data) {
      let enterpriseInfoData = this.enterpriseInfoData
      const { name, phone, email } = data || {}
      let linkInfo = `${name}&nbsp;&nbsp;&nbsp;&nbsp;${phone}&nbsp;&nbsp;&nbsp;&nbsp;${email}`
      this.enterpriseList[4]['html'] = (name && linkInfo) || '-'
      this.enterpriseInfoData = enterpriseInfoData
    },
    // 设置账号详情
    setAccountInfo(data) {
      const { nickName, phone, createdTime, email } = data || {}
      this.accountList = [
        { name: '用户名称', value: nickName || '-', iconCss: 'icon-edit', type: 'userName' },
        { name: '账号类型', value: '企业/机构账号' },
        { name: '注册时间', value: createdTime || '-' },
        { name: '注册手机号', value: phone || '-' },
        { name: '联系邮箱', value: email || '-', iconCss: 'icon-edit', type: 'userEmail' }
      ]
    },
    // 获取实名认证信息和企业机构认证信息
    getAuthInfo() {
      if (this.authLoading) return
      this.authLoading = true
      Promise.all([realNameInfo(), enterpriseInfo()])
        .then(res => {
          const realNameInfoData = (res && res[0]) || null
          const enterpriseInfoData = (res && res[1]) || null

          const { auditStatus, auditTime, idCard, name } = realNameInfoData || {}

          let newauditTime = auditTime && dayjs(auditTime).format('YYYY-MM-DD HH:mm:ss')

          this.realnameList = [
            { name: '认证状态', value: AUDITSTATUS_TYPE[auditStatus], iconCss: auditStatus === 2 ? 'success' : 'fail' },
            { name: '真实姓名', value: name || '-' },
            { name: '身份证号', content: idCard, value: idCard ? '已上传' : '未上传', iconCss: idCard ? 'success' : 'fail' },
            { name: '认证时间', value: newauditTime || '-' }
          ]

          const {
            auditStatus: status,
            contact,
            creditCode,
            email,
            name: enterpriseName,
            phone,
            workbenchUserRole
          } = enterpriseInfoData || {}

          let linkInfo = `${contact}&nbsp;&nbsp;&nbsp;&nbsp;${phone}&nbsp;&nbsp;&nbsp;&nbsp;${email}`

          // 更新store 企业认证状态
          this.updateUserInfo({ auditStatus: status })

          this.enterpriseList = [
            { name: '身份类别', value: IDTYPE[workbenchUserRole] || '-' },
            { name: '认证状态', value: AUDITSTATUS_TYPE[status], iconCss: status === 2 ? 'success' : 'fail' },
            { name: '企业名称', value: enterpriseName || '-' },
            { name: '社会信用代码', value: creditCode || '-' },
            { name: '联系人信息', html: (contact && linkInfo) || '-' }
          ]

          this.realNameInfoData = realNameInfoData
          this.enterpriseInfoData = enterpriseInfoData
        })
        .finally(() => {
          this.authLoading = false
        })
    }
  },
  created() {
    // this.getAccountInfo()
    this.getAuthInfo()
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/account.scss';
</style>
