<template>
  <div v-if="isShow" class="ls-prompt-dialog">
    <div class="wrap" :class="{'is-connect' : isConnect}">
      <div class="contents">
        <el-form :model="formModel" :rules="rules" ref="formModel" :label-width="labelWidth">
          <div class="ls-label">{{ title }}</div>

          <template v-if="isUserName">
            <el-form-item prop="nickName">
              <el-input v-model="formModel.nickName" maxLength="20" placeholder="请输入用户名称" />
            </el-form-item>
            <div class="align">
              <el-button type="primary" @click="saveName('formModel')" class="el-ls-btns" :loading="loading">保存</el-button>
            </div>
          </template>

          <template v-if="isUserEmail">
            <el-form-item prop="email">
              <el-input v-model="formModel.email" placeholder="请输入联系邮箱" maxlength="50"/>
            </el-form-item>
            <div class="align">
              <el-button @click="saveEmail('formModel')" class="el-ls-btns" :loading="loading">保存</el-button>
            </div>
          </template>

          <template v-else-if="isConnect">
            <el-form-item label="联系人姓名" prop="contact">
              <el-input v-model="formModel.contact" placeholder="请输入联系人姓名" maxLength="20"/>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="formModel.phone" placeholder="请输入联系电话" maxlength="11"/>
            </el-form-item>
            <el-form-item label="联系邮箱" prop="email">
              <el-input v-model="formModel.email" placeholder="请输入联系邮箱" maxlength="50"/>
            </el-form-item>
            <el-form-item>
              <div class="hint">联系人电话和邮箱地址会用于接收系统的部分重要 服务通知，请谨慎填写，以免影响到您的业务使用。</div>
            </el-form-item>
            <div class="btns">
              <el-button type="primary" @click="saveConnect('formModel')" class="el-ls-btns" :loading="loading">确认变更</el-button>
              <el-button plain @click="close" class="el-ls-btns" :loading="loading">取消</el-button>
            </div>
          </template>
        </el-form>
      </div>
      <div class="ls-icons icon-close" @click="close"></div>
    </div>
  </div>
</template>

<script>
/**
 * 变更联系人弹窗
 */
import { editAccountInfo, changeContact } from '@/api/interface/account'

export default {
  data() {
    return {
      loading: false,
      formModel: {
        nickName: undefined,
        contact: undefined,
        phone: undefined,
        email: undefined
      },
      rules: {
        nickName: { required: true, message: '请输入用户名称' },
        contact: { required: true, message: '请输入联系人姓名' },
        phone: [
          { required: true, message: '请输入联系电话' },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入联系邮箱' },
          { pattern: /^[a-zA-Z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的邮箱格式', trigger: 'blur' }
        ]
      },
      isShow: false
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    item(val) {
      if (val) {
        this.formModel = {
          nickName: undefined,
          contact: undefined,
          phone: undefined,
          email: undefined
        }
        this.isShow = val.isShow
      }
    }
  },
  computed: {
    isUserName() {
      return this.item.type == 'userName'
    },
    isUserEmail() {
      return this.item.type == 'userEmail'
    },
    isConnect() {
      return this.item.type == 'connectInfo'
    },
    title() {
      let txt = ''
      if (this.isUserName) {
        txt = '用户名称'
      } else if (this.isUserEmail) {
        txt = '联系邮箱'
      } else if (this.isConnect) {
        txt = '变更联系人信息'
      }
      return txt
    },
    labelWidth() {
      let w = '0px'
      if (this.isConnect) {
        w = '1.3rem'
      }
      return w
    }
  },
  methods: {
    // 关闭
    close() {
      this.isShow = false
    },
    // 用户名
    saveName(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { nickName } = this.formModel
          this.onEditAccount({
            nickname:nickName
          })
        } else {
          return false
        }
      })
    },
    // 邮箱
    saveEmail(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { email } = this.formModel
          this.onEditAccount({
            email
          })
        } else {
          return false
        }
      })
    },
    // 变更联系人
    saveConnect(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { contact, phone, email } = this.formModel
          this.onChangeContact({
            name: contact,
            phone,
            email
          })
        } else {
          return false
        }
      })
    },
    // 编辑用户昵称和联系邮箱
    onEditAccount(data) {
      if (this.loading) return
      this.loading = true
      editAccountInfo(data)
        .then(res => {
          const { nickname, email } = res || {}
          this.$message.success('修改成功')
          this.isShow = false
          this.$emit('updateInfo', { nickName:nickname, email })
        })
        .finally(() => {
          this.loading = false
        })
    },
    //  变更联系人
    onChangeContact(data) {
      if (this.loading) return
      this.loading = true
      changeContact(data)
        .then(res => {
          this.$message.success('变更联系人成功')
          this.isShow = false
          this.$emit('updateContactsInfo', res)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/el/_form.scss';

.ls-prompt-dialog {
  @extend %com-dialog-fixed;
  .wrap {
    @extend %v-h-center;
    padding: 32px 70px 51px;
    border: 1px solid #dddddd;
    background-color: #fff;
    &.is-connect {
      padding: 32px 96px 51px 70px;
    }
  }
  .ls-label {
    margin-bottom: 32px;
    text-align: center;
  }
  .el-ls-btns {
    margin: 20px auto 0;
    padding: 8px 45px;
  }
  .icon-close {
    @extend %com-dialog-close;
  }
  .btns {
    margin: 50px auto 30px;
    display: flex;
    width: 358px;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
